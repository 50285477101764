import React, { useEffect, useState } from "react";
import linkedInImg from "../../../images/linkedin.svg";
import skypeImg from "../../../images/skype.svg";
import gmailImg from "../../../images/gmail.svg";
import { Button } from "../../Button";
import { Link } from "../../Link";
import * as styles from "./Contacts.module.scss";
import {
  CONTACT_US_BOOK_CALL_URL,
  CONTACT_US_PERSON_EMAIL,
  CONTACT_US_PERSON_URL,
} from "../../../config";
import cn from "classnames";

const Contacts = ({ mode }: { mode: "desktop" | "mobile" }) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);
  
  const key = isClient ? `client` : `server`;

  return (
    <div
      key={key}
      className={cn(styles.container, {
        [styles.desktopContainer]: mode === "desktop",
        [styles.mobileContainer]: mode === "mobile",
      })}
    >
      <ul className={styles.list}>
        <li>
          <Link to={CONTACT_US_PERSON_URL}>
            <img alt="LinkedIn" title="LinkedIn" src={linkedInImg} />
          </Link>
        </li>
        <li>
          <Link ext to="skype:allokym?chat">
            <img alt="Skype" title="Skype" src={skypeImg} />
          </Link>
        </li>
        <li>
          <Link ext to="mailto:mykola@leanylabs.com?subject=Hi%20Mykola">
            <img alt="Email" title="Email" src={gmailImg} />
          </Link>
        </li>
      </ul>
      <Button
        size="small"
        to={CONTACT_US_BOOK_CALL_URL}
        className={styles.bookButton}
      >
        Book a Call
      </Button>
    </div>
  );
};

export { Contacts };
