import React from "react";
import cn from "classnames";
import * as styles from "./Button.module.scss";
import { Link } from "./Link";

type Props = {
  className?: string;
  size?: "normal" | "small";
  variant?: "default" | "cta" | "out" | "none";
  to?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: React.MouseEventHandler;
  onKeyPress?: React.KeyboardEventHandler;
};

export const Button: React.FC<Props> = ({
  className,
  size = "normal",
  variant = "default",
  type = "button",
  to,
  ...rest
}) => {
  const props = {
    className: cn(className, {
      [styles.small]: size === "small",
      [styles.btn]: variant !== "none",
      [styles.out]: variant === "out",
      [styles.cta]: variant === "cta",
      [styles.none]: variant === "none",
    }),
    ...rest,
  };

  if (to) {
    return <Link {...props} to={to} />;
  }

  return <button {...props} type={type} />;
};
