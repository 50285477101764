import React, { useMemo, useCallback, useContext, useState } from "react";
import { useLocalStorage } from "../../utils/localStorage";
import { CookieConsent } from "./CookieConsent";
import { CookiePreferencesDialog } from "./CookiePreferences";
import { CONSENT_KEY } from "../../config";

type CookieContextType = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  consent: string;
  setConsent: (consent: string) => void;
};

const CookieContext = React.createContext<CookieContextType>({
  visible: false,
  setVisible: () => {},
  consent: "",
  setConsent: () => {},
});

export function useCookieConsent() {
  const { consent, setConsent } = useContext(CookieContext);

  return { consent, setConsent };
}

export function useShowCookiePreferences() {
  const { setVisible } = useContext(CookieContext);

  const showCookiePreferences = useCallback(
    (visible?: boolean) => setVisible(visible ?? true),
    []
  );

  return showCookiePreferences;
}

export function useCookiePreferencesVisible() {
  const { visible } = useContext(CookieContext);

  return visible;
}

export const CookieContainer: React.FC = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [consent, setConsentState] = useLocalStorage(CONSENT_KEY, "");

  const setConsent = useCallback(
    (v) => {
      setConsentState(v);
      setVisible(false);
    },
    [setConsentState, setVisible]
  );

  const contextValue = useMemo(
    () => ({ visible, setVisible, consent: consent || "", setConsent }),
    [visible, setVisible, consent, setConsent]
  );

  return (
    <CookieContext.Provider value={contextValue}>
      {children}
      <CookieConsent />
      <CookiePreferencesDialog />
    </CookieContext.Provider>
  );
};
