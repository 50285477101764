import React, { MouseEventHandler } from "react";
import cn from "classnames";
import "./Hamburger.module.scss";

type Props = {
  open: boolean;
  onClick?: MouseEventHandler;
  className?: string;
};

export const Hamburger: React.FC<Props> = ({ open, className, onClick }) => {
  return (
    <button
      aria-label="Mobile Menu"
      onClick={onClick}
      className={cn("hamburger hamburger--spring", className, {
        "is-active": open,
      })}
      type="button"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};
