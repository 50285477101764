import React from "react";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import * as styles from "./Section.module.scss";

type SectionTitleProps = {
  className?: string;
};

export const SectionTitle: React.FC<SectionTitleProps> = ({
  children,
  className,
}) => {
  const [ref, inView] = useInView();

  return (
    <h2
      ref={ref}
      className={cn(styles.title, className, { [styles.visible]: inView })}
    >
      {children}
    </h2>
  );
};

type Props = React.PropsWithChildren<{
  className?: string;
  containerOnly?: boolean;
  id?: string;
}>;

export const Section = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className, containerOnly, ...rest }, ref) => {
    return (
      <section
        ref={ref}
        className={cn(
          styles.container,
          !containerOnly && styles.section,
          className
        )}
        {...rest}
      >
        {children}
      </section>
    );
  }
);

type ContainerProps = {
  className?: string;
};

export const Container: React.FC<ContainerProps> = ({
  className,
  children,
}) => {
  return <div className={cn(styles.container, className)}>{children}</div>;
};
