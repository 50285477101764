import React from "react";

export const CloseSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 378.3 378.3"
  >
    <polygon points="378.3 28.3 350 0 189.2 160.9 28.3 0 0 28.3 160.9 189.2 0 350 28.3 378.3 189.2 217.4 350 378.3 378.3 350 217.4 189.2" />
  </svg>
);
