import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useTimeDelay } from "../../hooks/useTimeDelay";
import { useInView } from "react-intersection-observer";
import cn from "classnames";

const widgetInfo = {
  blurb: {
    type: "14",
    height: 50,
    width: 220,
  },
  compact: {
    type: "2",
    height: 55,
    width: 200,
  },
};

type Props = {
  className?: string;
  type: keyof typeof widgetInfo;
  light?: boolean;
};

export const ClutchReviews: React.FC<Props> = ({ className, type, light }) => {
  const delay = useTimeDelay(10000);
  const loadedRef = useRef(false);

  useEffect(() => {
    const updateClutch = (id: NodeJS.Timeout) => {
      const windowA = window as any;
      // console.log("clutch: update");
      if (!windowA.CLUTCHCO) return false;

      loadedRef.current = true;
      windowA.CLUTCHCO.Init();
      clearInterval(id);
      // console.log("clutch: Horay");
      return true;
    };

    const id = setInterval(() => {
      updateClutch(id);
    }, 500);

    updateClutch(id);

    return () => clearInterval(id);
  }, []);

  const [ref, inView] = useInView({ triggerOnce: true });

  const loadClutch = (inView || delay) && !loadedRef.current;

  const info = widgetInfo[type];

  return (
    <>
      <div
        ref={ref}
        style={{ width: info.width, height: info.height }}
        className={cn("clutch-widget", className)}
        data-url="https://widget.clutch.co"
        data-nofollow="true"
        data-widget-type={info.type}
        data-height={"" + info.height}
        data-darkbg={light ? "true" : undefined}
        data-clutchcompany-id="1427276"
      />
      {/* <div
        style={{ width: "200px" }}
        className="clutch-widget"
        data-url="https://widget.clutch.co"
        data-widget-type="2"
        data-height="50"
        data-darkbg="1"
        data-clutchcompany-id="1427276"
      ></div> */}
      {loadClutch && (
        <Helmet>
          <script
            async
            defer
            type="text/javascript"
            src="https://widget.clutch.co/static/js/widget.js"
          />
        </Helmet>
      )}
    </>
  );
};
