export const essentialCookieKey = "essential";

export const cookieGroups = [
  {
    key: essentialCookieKey,
    name: "Essential",
    desc: "These cookies are strictly necessary to provide you with services available through our Websites and to use some of its features, such as access to secure areas.",
    cookies: ["COMPASS", "__tlbcpv"],
  },
  {
    key: "performance",
    name: "Performance and Functionality",
    desc: "These cookies are used to enhance the performance and functionality of our Websites but are non-essential to their use. However, without these cookies, certain functionality (like videos) may become unavailable.",
    cookies: ["__cfruid"],
  },
  {
    key: "analytics",
    name: "Analytics and Customization",
    desc: "These cookies collect information that is used either in aggregate form to help us understand how our Websites are being used or how effective our marketing campaigns are, or to help us customize our Websites for you.",
    cookies: [
      "#collect",
      "_gid",
      "_gat#",
      "_ga",
      "S",
      "NID",
      "_ga_#",
      "__cf_bm",
    ],
  },
  {
    key: "advertising",
    name: "Advertising",
    desc: "These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.",
    cookies: ["VISITOR_INFO1_LIVE", "YSC"],
  },
  {
    key: "unclassified",
    name: "Unclassified",
    desc: "These are cookies that have not yet been categorized. We are in the process of classifying these cookies with the help of their providers.",
    cookies: ["cp_session"],
  },
];

export type CookieConsent = boolean | string | string[] | undefined;

export function parseConsent(consent: CookieConsent) {
  if (consent === true) consent = "all";
  if (Array.isArray(consent)) return consent;
  if (!consent || typeof consent !== "string") return [];
  if (consent === "all") return cookieGroups.map((x) => x.key);

  const result = consent.split(",").map((x) => x.trim());
  if (!result.includes(essentialCookieKey)) result.push(essentialCookieKey);
  return result;
}

export function serializeConsent(consentArr: string[]) {
  if (!consentArr || !Array.isArray(consentArr)) return essentialCookieKey;
  if (!consentArr.includes(essentialCookieKey))
    consentArr = [...consentArr, essentialCookieKey];
  if (cookieGroups.every((g) => consentArr.includes(g.key))) return "all";
  return consentArr
    .filter((x) => cookieGroups.some((g) => g.key === x))
    .join(",");
}

export function hasCookieConsent(
  consent: CookieConsent,
  cookies: CookieConsent
) {
  const consentArr = parseConsent(consent);
  const cookiesArr = parseConsent(cookies);
  // console.log("hasCookieConsent", consentArr, cookiesArr);
  return cookiesArr.every((x) => consentArr.some((c) => c === x));
}

export function getUrlCookies(url: string | undefined) {
  const result: string[] = [];
  if (!url) return result;
  if (url.includes("codepen.io") || url.includes("cdpn.io"))
    result.push("performance", "analytics", "unclassified");
  if (url.includes("google.com")) result.push("analytics");
  if (url.includes("youtube.com")) result.push("analytics", "advertising");

  return result.length ? Array.from(new Set(result)) : result;
}

export function mergeCookies(first: CookieConsent, second: CookieConsent) {
  return Array.from(new Set([...parseConsent(first), ...parseConsent(second)]));
}
