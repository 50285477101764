import { useCookieConsent } from "./CookieContainer";
import { hasCookieConsent } from "../../utils/cookies";
import { GTAG_IDS } from "../../config";

export function useGtagInit() {
  const { consent } = useCookieConsent();

  if (typeof window === "undefined") return;

  const gtagAllowed =
    navigator?.doNotTrack != "1" &&
    (window as any).doNotTrack != "1" &&
    hasCookieConsent(consent, "analytics");

  const wasDisabled = (window as any)[`ga-disable-${GTAG_IDS[0]}`];
  for (const id of GTAG_IDS) {
    (window as any)[`ga-disable-${id}`] = !gtagAllowed;
  }

  function gtag() {
    (window as any).dataLayer?.push(arguments);
  }

  window.gtag = gtagAllowed ? gtag : function () {};

  if (gtagAllowed && wasDisabled) {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    window.gtag(`event`, `page_view`, { page_path: pagePath });
  }
}
