import React from "react";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import { ContactForm } from "./ContactForm";
import {
  formats,
  Person,
  PersonImgProps,
  PersonAvatarWidth,
  PersonAvatarHeight,
} from "../Person";
import { Link } from "../Link";
import { Section, SectionTitle } from "../Section";
import * as styles from "./ContactUs.module.scss";
import { Button } from "../Button";
import { Buttons } from "../Buttons";
import { StaticImage } from "gatsby-plugin-image";
import { DefaultImageFormats } from "../Image";
import { Text } from "../Text/Text";
import { Contacts } from "./Contacts/Contacts";

const mykolaUrl = "https://www.linkedin.com/in/mykola-pustovoychenko-b7788051/";
const mykolaName = "Mykola Pustovoychenko";

export const ContactUs: React.FC = () => {
  const [personRef, personInView] = useInView();
  const [contactRef, contactInView] = useInView();

  return (
    <Section id="contact-us">
      <div className={styles.contact}>
        <div
          ref={personRef}
          className={cn(styles.person, "show-up", {
            visible: personInView,
          })}
        >
          <Person
            linkedIn={mykolaUrl}
            avatar={
              <StaticImage
                src="./mykola_240.jpg"
                alt={mykolaName}
                title={mykolaName}
                width={282}
                height={282}
                formats={["auto", "webp"]}
              />
            }
            name={mykolaName}
            position="Co-Founder at LeanyLabs"
          >
            <Contacts mode="mobile" />
          </Person>

          <Contacts mode="desktop" />
        </div>
        <div
          ref={contactRef}
          className={cn(styles.rightCol, "show-up", {
            visible: contactInView,
          })}
        >
          <h2 className={styles.title}>Contact Us</h2>
          <ContactForm className={styles.form} />
        </div>
      </div>
    </Section>
  );
};
