import { useState, useCallback } from "react";

export function localStorageSet(key: string, value: any) {
  if (typeof localStorage === "undefined") return;

  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
}

export function localStorageGet(key: string, defaultValue: any) {
  if (typeof localStorage === "undefined") return defaultValue;

  try {
    const v = localStorage.getItem(key);
    return v !== null ? JSON.parse(v) : defaultValue;
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
}

export function useLocalStorage(key: string, initialValue: any) {
  const [state, setState] = useState(
    localStorageGet(key, initialValue)
  );

  const setValue = useCallback(
    (value) => {
      localStorageSet(key, value);
      setState(value);
    },
    [setState, key]
  );

  return [state, setValue];
}
