export type ContactUsData = {
  name: string;
  email: string;
  message: string;
  consent: boolean;
  captcha: string;
};

export async function postContactUs({
  name,
  email,
  message,
  captcha,
  consent,
}: ContactUsData) {
  const values: ContactUsData = { name, email, message, captcha, consent };
  // console.log("ContactUs", values);
  // return;

  const res = await fetch("/.netlify/functions/contact", {
    method: "POST",
    body: JSON.stringify(values),
  });
  if (res.status >= 400) {
    const json = await res.json();
    const error = json.error || "Server error";
    console.error(error);
    throw new Error(error);
  }
}
