import React, { PropsWithChildren } from "react";

import { Link as GatsbyLink } from "gatsby";
import cn from "classnames";

type OutboundProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
};

const OutboundLink = React.forwardRef<HTMLAnchorElement, OutboundProps>(
  (props, ref) => (
    <a
      ref={ref}
      {...props}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
        const redirect =
          !(
            e.button !== 0 ||
            e.altKey ||
            e.ctrlKey ||
            e.metaKey ||
            e.shiftKey ||
            e.defaultPrevented
          ) && !(props.target && props.target.toLowerCase() !== `_self`);

        if (window.gtag) {
          window.gtag(`event`, `click`, {
            event_category: `outbound`,
            event_label: props.href,
            transport_type: redirect ? `beacon` : ``,
            event_callback: function () {
              if (redirect) {
                document.location = props.href;
              }
            },
          });
        } else {
          if (redirect) {
            document.location = props.href;
          }
        }

        return false;
      }}
    />
  )
);

type Props = {
  to: string;
  noIndex?: boolean;
  ext?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const Link = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<Props>
>(({ noIndex, ext, ...props }, ref) => {
  const external =
    ext || props.to.includes("://") || props.to.startsWith("mailto:");

  const rel =
    cn(props.rel, {
      noopener: external,
      noindex: noIndex,
      nofollow: noIndex,
    }) || undefined;

  if (external) {
    return (
      <OutboundLink
        ref={ref}
        href={props.to}
        {...props}
        target="_blank"
        rel={rel}
      />
    );
  }

  return (
    <GatsbyLink innerRef={ref} {...props} rel={rel}>
      {props.children}
    </GatsbyLink>
  );
});
