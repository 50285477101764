import React from "react";
import { FieldError } from "react-hook-form";
import TextareaAutosize from 'react-textarea-autosize'
import classNames from "classnames";

import { FormError } from "../../FormError";
import * as styles from "./Input.module.scss";

interface InputProps {
  placeholder: string;
  name: string;
  value: string;
  error?: FieldError;
  multiline?: boolean;
  onBlur: () => void;
  onChange: () => void;
}

const Input = ({
  placeholder,
  name,
  value,
  error,
  multiline,
  onBlur,
  onChange,
}: InputProps) => {
  const fieldProps = {
    name,
    value,
    placeholder,
    "aria-label": placeholder,
    className: classNames(styles.input, {
      [styles.hasError]: error,
      [styles.textarea]: multiline,
    }),
    onBlur,
    onChange,
  };

  return (
    <div>
      {multiline ? <TextareaAutosize {...fieldProps} /> : <input {...fieldProps} />}
      <FormError error={error?.message} />
    </div>
  );
};

export { Input };
