import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import defaultOgImage from "./default_og_image.png";
import { ImageType } from "../types/images";

type Props = {
  ogImage?: ImageType;
  title?: string;
  lang?: string;
  description?: string;
  article?: boolean;
  noIndex?: boolean;
  pureTitle?: boolean;
  ogFullTitle?: boolean;
  maxImagePreview?: string;
  meta?: React.MetaHTMLAttributes<HTMLMetaElement>[];
};

export const Seo: React.FC<Props> = ({
  description,
  lang = "en",
  meta,
  title,
  ogFullTitle,
  ogImage,
  article,
  pureTitle,
  noIndex,
  maxImagePreview,
}) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query SeoComponent {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
      }
    `
  );

  if (
    ogImage &&
    typeof ogImage !== "string" &&
    ogImage?.childImageSharp?.resize?.src
  ) {
    ogImage = ogImage?.childImageSharp?.resize?.src;
  }
  // console.log(`Title: ${title} og:image: ${ogImage}`);

  const { pathname } = useLocation();

  const metaDescription = description;

  const ogTitle = ogFullTitle ? `${title} | ${siteMetadata.title}` : title;

  const defaultMeta: React.MetaHTMLAttributes<HTMLMetaElement>[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:url`,
      content: `${siteMetadata.siteUrl}${pathname}`,
    },
    {
      property: `og:title`,
      content: ogTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: article ? `article` : `website`,
    },
    // JPG, PNG, WEBP
    {
      property: `og:image`,
      content: `${siteMetadata.siteUrl}${ogImage || defaultOgImage}`,
    },
    { property: `og:image:alt`, content: ogImage ? title : "LeanyLabs" },
    { property: `og:image:width`, content: "1200" },
    { property: `og:image:height`, content: "627" },
    { property: `fb:app_id`, content: `303905057460040` },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    { name: `twitter:site`, content: `@LeanyLabs` },
    { name: `twitter:creator`, content: `@LeanyLabs` },

    // Twitter will fallback to og:title
    // {
    //   name: `twitter:title`,
    //   content: ogTitle,
    // },
    // Twitter will fallback to og:description
    // {
    //   name: `twitter:description`,
    //   content: metaDescription,
    // },
    // don't set - it's the default
    // {
    //   name: `robots`,
    //   content: "index, follow",
    // },
  ];

  const robots = [];

  if (noIndex) {
    robots.push("noindex");
  }

  if (maxImagePreview) {
    robots.push(`max-image-preview:${maxImagePreview}`);
  }

  if (robots.length) {
    defaultMeta.push({ name: "robots", content: robots.join(", ") });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={pureTitle ? "%s" : `%s | ${siteMetadata.title}`}
      meta={meta ? defaultMeta.concat(meta) : defaultMeta}
    />
  );
};

export const seoQuery = graphql`
  fragment OgImageData on File {
    childImageSharp {
      resize(width: 1200, height: 627, cropFocus: NORTH, quality: 90) {
        src
      }
    }
  }
`;
