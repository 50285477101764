import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "../utils/scrollLock";
import * as styles from "./ModalDialog.module.scss";
import { Button } from "./Button";
import { Container } from "./Section";
import { CloseSvg } from "./Svg/CloseSvg";

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
type ModalOverlayProps = {
  open: boolean;
  close?: () => void;
  className?: string;
};

export const ModalOverlay: React.FC<ModalOverlayProps> = ({
  open,
  close,
  className,
  children,
}) => {
  const [realOpen, setRealOpen] = useState(open);
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setRealOpen(open);
    if (open) {
      disableBodyScroll(dialogRef.current, { reserveScrollBarGap: true });
    } else {
      clearAllBodyScrollLocks();
    }
  }, [open, setRealOpen]);

  useEffect(() => {
    return () => clearAllBodyScrollLocks();
  }, []);

  if (!open) return null;

  return (
    <div
      ref={dialogRef}
      className={cn(styles.overlay, { [styles.open]: realOpen }, className)}
      onClick={close}
      onKeyPress={close}
      role="dialog"
    >
      {children}
    </div>
  );
};

export const ModalDialog: React.FC<ModalOverlayProps> = ({
  open,
  close,
  className,
  children,
}) => {
  return (
    <ModalOverlay open={open} close={close} className={className}>
      <Container className={cn(styles.dialog, className)}>{children}</Container>
    </ModalOverlay>
  );
};

type ClassNameProps = {
  className?: string;
};

export const ModalContent: React.FC<ClassNameProps> = ({
  className,
  children,
}) => <div className={cn(styles.content, className)}>{children}</div>;

export const ModalHeader: React.FC<ClassNameProps> = ({
  className,
  children,
}) => <div className={cn(styles.header, className)}>{children}</div>;

export const ModalActions: React.FC<ClassNameProps> = ({
  className,
  children,
}) => <div className={cn(styles.actions, className)}>{children}</div>;

type ModalCloseProps = {
  onClick?: React.MouseEventHandler;
  overlay?: boolean;
};

export const ModalClose: React.FC<ModalCloseProps> = ({ onClick, overlay }) => (
  <Button
    variant="none"
    className={cn(overlay ? styles.closeOverlay : styles.close)}
    aria-label="Close"
    onClick={onClick}
  >
    <CloseSvg />
  </Button>
);
