import React from "react";
import { Link } from "./Link";
import { graphql } from "gatsby";
import cn from "classnames";
import * as styles from "./Person.module.scss";
import { GatsbyImageProps, ImageFormat } from "gatsby-plugin-image";

type Props = {
  name: string;
  avatar: React.ReactNode;
  linkedIn: string;
  position: string;
  location?: string;
  className?: string;
  children?: React.ReactNode;
};

export const Person: React.FC<Props> = ({
  className,
  linkedIn,
  avatar,
  name,
  position,
  location,
  children,
}) => {
  return (
    <Link className={cn(styles.person, className)} to={linkedIn}>
      <div className={styles.avatar}>{avatar}</div>

      <h3 className={styles.personName}>{name}</h3>
      <small className={styles.personTitle}>{position}</small>
      {!!location && <small>{location}</small>}
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {children}
      </div>
    </Link>
  );
};

export const parsonQuery = graphql`
  fragment AvatarImgData on File {
    childImageSharp {
      gatsbyImageData(width: 240, height: 240, formats: [AUTO, WEBP])
    }
  }
`;
