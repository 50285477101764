// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--c6acq";
export var row = "Footer-module--row--uA_Lo";
export var topRow = "Footer-module--topRow--5B1rA";
export var col = "Footer-module--col--ajcIG";
export var middleRow = "Footer-module--middleRow--2nAAo";
export var bottomRow = "Footer-module--bottomRow--39O4Q";
export var socials = "Footer-module--socials--3QMVh";
export var email = "Footer-module--email--fy7r8";
export var awards = "Footer-module--awards--3Vsc1";
export var brand = "Footer-module--brand--2KUHY";
export var brandText = "Footer-module--brandText--25UNs";
export var columnTitle = "Footer-module--columnTitle--1fJF8";
export var serviceLinks = "Footer-module--serviceLinks--19zhc";
export var links = "Footer-module--links--1gulL";
export var address = "Footer-module--address--1dGbW";