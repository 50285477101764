import React, { useState } from "react";
import {
  ModalContent,
  ModalDialog,
  ModalHeader,
  ModalClose,
  ModalActions,
} from "../ModalDialog";
import {
  useCookieConsent,
  useCookiePreferencesVisible,
  useShowCookiePreferences,
} from "./CookieContainer";

import { getCookieUrl, getPrivacyUrl } from "../../utils/urls";
import { useCallback } from "react";
import { Link } from "../Link";
import { useEffect } from "react";
import {
  parseConsent,
  cookieGroups,
  serializeConsent,
  essentialCookieKey,
} from "../../utils/cookies";
import * as styles from "./CookiePreferences.module.scss";
import { Button } from "../Button";

export const CookiePreferencesDialog = () => {
  const visible = useCookiePreferencesVisible();
  const showCookiePreferences = useShowCookiePreferences();

  const handleClose = useCallback(
    () => showCookiePreferences(false),
    [showCookiePreferences]
  );

  const { consent, setConsent } = useCookieConsent();
  const [consentEditor, setConsentEditor] = useState<string[]>([]);
  useEffect(() => {
    setConsentEditor(parseConsent(consent || "all"));
  }, [consent]);

  const handleToggle = useCallback(
    (e) => {
      // e.preventDefault();
      const { value } = e.target;
      if (value === essentialCookieKey) return;
      if (consentEditor.includes(value)) {
        setConsentEditor(consentEditor.filter((x) => x !== value));
      } else {
        setConsentEditor([...consentEditor, value]);
      }
    },
    [consentEditor, setConsentEditor]
  );

  const handleAccept = useCallback(() => {
    setConsent(serializeConsent(consentEditor));
    handleClose();
  }, [setConsent, consentEditor, handleClose]);

  return (
    <ModalDialog open={visible} className={styles.preferences}>
      <ModalHeader>
        <h4>Cookie Preferences</h4>
        <ModalClose onClick={handleClose} />
      </ModalHeader>
      <p className={styles.desc}>
        We use different types of cookies to optimize your experience on our
        website. Click on the categories below to learn more about their
        purposes. You may choose which types of cookies to allow and can change
        your preferences at any time. Remember that disabling cookies may affect
        your experience on the website. You can learn more about how we use
        cookies by visiting our{" "}
        <Link ext to={getCookieUrl()}>
          Cookie Policy
        </Link>{" "}
        and{" "}
        <Link ext to={getPrivacyUrl()}>
          Privacy Policy.
        </Link>
      </p>
      <ModalContent>
        {cookieGroups.map((x) => (
          <div key={x.key}>
            <div className={styles.checkbox}>
              <label>
                <input
                  type="checkbox"
                  aria-label={x.name}
                  value={x.key}
                  checked={consentEditor.includes(x.key)}
                  onChange={handleToggle}
                  disabled={x.key === essentialCookieKey}
                />
                {x.name} ({x.cookies.length})
              </label>
              <Link ext to={`${getCookieUrl()}#cookies-${x.key}`}>
                Details
              </Link>
            </div>
            <p className={styles.desc}>{x.desc}</p>
          </div>
        ))}
      </ModalContent>
      <ModalActions>
        <Button variant="out" size="small" onClick={handleClose}>
          Discard Changes
        </Button>
        <Button variant="cta" size="small" onClick={handleAccept}>
          {serializeConsent(consentEditor) === "all"
            ? "Accept All"
            : "Save and Finish"}
        </Button>
      </ModalActions>
    </ModalDialog>
  );
};
