import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { getBlogPostUrl } from "../../utils/urls";
import { Link } from "../Link";
import { Section, SectionTitle } from "../Section";
import * as styles from "./Awards.module.scss";
import cn from "classnames";
import { ClutchReviews } from "./ClutchReviews";

type Props = {
  center?: boolean;
};

export const AwardsList: React.FC<Props> = ({ center }) => {
  return (
    <ul className={cn(styles.awards, !!center && styles.center)}>
      <li>
        <Link to={getBlogPostUrl("clutch-year-2021")}>
          <StaticImage
            src="./clutch_b2b_global_2021.png"
            alt="Top B2B Company 2021"
            width={131}
            height={112}
            transformOptions={{ fit: "inside" }}
            quality={100}
            formats={["auto", "webp"]}
          />
        </Link>
      </li>
      <li>
        <Link to={getBlogPostUrl("clutch-top-b2b-ukraine-2020")}>
          <StaticImage
            src="./clutch_b2b_ukraine_2020.png"
            alt="Top B2B Company Ukraine 2021"
            width={100}
            height={112}
            transformOptions={{ fit: "inside" }}
            quality={100}
            formats={["auto", "webp"]}
          />
        </Link>
      </li>
      <li>
        <Link to="https://www.goodfirms.co/company/leanylabs">
          <StaticImage
            src="./goodfirms_webapp_2021_t.png"
            alt="Top Web Application Development Company"
            width={122}
            height={112}
            quality={100}
            transformOptions={{ fit: "inside" }}
            formats={["auto", "webp"]}
          />
        </Link>
      </li>
      <li>
        <Link to="https://superbcompanies.com/categories/web-app-development-companies/">
          <StaticImage
            src="./superbcompanies_webdev_2021.png"
            alt="Top Web Development Companies 2021"
            width={112}
            height={112}
            transformOptions={{ fit: "inside" }}
            quality={100}
            formats={["auto", "webp"]}
          />
        </Link>
      </li>
    </ul>
  );
};
