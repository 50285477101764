import React from "react";
import cn from "classnames";
import * as styles from "./FormError.module.scss";

type Props = {
  error?: string;
  className?: string;
};

export const FormError: React.FC<Props> = ({ error, className, children }) => {
  if (!error && !children) return null;
  return <div className={cn(className, styles.error)}>{children ?? error}</div>;
};
