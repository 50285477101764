/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";

// has to be here, cause gatsby-plugin-netlify can't file files referenced only in the CSS
import "../../styles/all.scss";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { ContactUs } from "../ContactUs/ContactUs";
// import { FullStory } from "./Fullstory";
import { useHtmlDataScroll } from "../../hooks/useHtmlDataScroll";
import { ZoomImageProvider } from "./ZoomImageProvider";
import { CookieContainer } from "./CookieContainer";
import { useGtagInit } from "./gtag";
import * as styles from "./Layout.module.scss";

function GTagInit() {
  useGtagInit();
  return null;
}

type Props = {
  noContact?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  contactUrl?: string;
};

export const Layout: React.FC<Props> = ({
  children,
  noContact,
  noHeader,
  noFooter,
  contactUrl,
}) => {
  useHtmlDataScroll();

  return (
    <ZoomImageProvider>
      <CookieContainer>
        <GTagInit />
        <div className={styles.wrap}>
          <div className={styles.layout}>
            {!noHeader && <Header contactUrl={contactUrl} />}
            <main>
              {children}
              {!noContact && <ContactUs />}
            </main>

            {!noFooter && <Footer />}
            {/* <FullStory /> */}
          </div>
        </div>
      </CookieContainer>
    </ZoomImageProvider>
  );
};
