import React, { useState, useContext, useCallback, useRef } from "react";
import { ZoomImageUrl } from "../../types/images";
import { ModalOverlay, ModalClose } from "../ModalDialog";
import * as styles from "./ZoomImageProvider.module.scss";

const ZoomContext = React.createContext<(url: ZoomImageUrl) => void>(() => {});

export const ZoomImageProvider: React.FC = ({ children }) => {
  const [url, doSetUrl] = useState("");
  const lastUrl = useRef("");

  const setUrl = useCallback(
    (url: ZoomImageUrl) => {
      if (typeof url !== "string") url = url.publicURL;
      if (typeof url !== "string") return;
      doSetUrl(url);
      if (url) lastUrl.current = url;
    },
    [doSetUrl, lastUrl]
  );

  const close = useCallback(() => setUrl(""), [setUrl]);

  return (
    <ZoomContext.Provider value={setUrl}>
      {children}
      <ModalOverlay open={!!url} close={close} className={styles.zoom}>
        <div>
          <ModalClose overlay onClick={close} />
          <img src={lastUrl.current} alt="Zoom" className={styles.image} />
        </div>
      </ModalOverlay>
    </ZoomContext.Provider>
  );
};

export function useZoomImage(url: ZoomImageUrl | undefined) {
  const setZoom = useContext(ZoomContext);

  return useCallback(() => {
    if (url !== undefined && setZoom) {
      setZoom(url);
    }
  }, [url, setZoom]);
}

export function useZoomImageFunc() {
  return useContext(ZoomContext);
}
