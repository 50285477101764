import { useEffect } from "react";

export function useHtmlDataScroll() {
  return useEffect(() => {
    const doc = document.documentElement;

    let hasScrolled = false;
    const updateScrolled = () => {
      const scrolled = window.scrollY > 0;
      if (hasScrolled === scrolled) return;
      hasScrolled = scrolled;
      if (scrolled) {
        doc.dataset.scroll = "";
      } else {
        doc.removeAttribute("data-scroll");
      }
    };

    document.addEventListener("scroll", updateScrolled, { passive: true });

    updateScrolled();

    return () => document.removeEventListener("scroll", updateScrolled);
  });
}
