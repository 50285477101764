import { useEffect, useState } from "react";

export function useTimeDelay(timeout: number) {
  const [done, setDone] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => setDone(true), timeout);
    return () => clearTimeout(timeoutId);
  }, [timeout]);
  return done;
}
