import React, { useCallback } from "react";
import { getCookieUrl } from "../../utils/urls";
import { Link } from "../Link";
import { useCookieConsent, useShowCookiePreferences } from "./CookieContainer";
import * as styles from "./CookieConsent.module.scss";
import { Button } from "../Button";
import { Container } from "../Section";

export const CookieConsent = () => {
  const { consent, setConsent } = useCookieConsent();
  const showCookiePreferences = useShowCookiePreferences();

  const handleAccept = useCallback(() => setConsent("all"), [setConsent]);
  const handlePreferences = useCallback(
    (e) => {
      e.preventDefault();
      showCookiePreferences();
    },
    [showCookiePreferences]
  );

  // const done = useDidMount();
  // if (!done) return null;

  if (typeof window === "undefined" || consent) return null;

  return (
    <div className={styles.consent}>
      <Container>
        <div>
          We use cookies to improve user experience and analyze website traffic.
          By clicking "Accept", you agree to our website's cookie use as
          described in our{" "}
          <Link ext className="default" to={getCookieUrl()}>
            Cookie Policy
          </Link>
          . You can change your cookie settings at any time by clicking{" "}
          <Link to="#" className="default" onClick={handlePreferences}>
            "Preferences"
          </Link>
          .
        </div>
        <Button variant="out" size="small" onClick={handlePreferences}>
          Preferences
        </Button>
        <Button variant="cta" size="small" onClick={handleAccept}>
          Accept
        </Button>
      </Container>
    </div>
  );
};
