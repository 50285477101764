import React from "react";

export const CheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      d="M4.41162 11.1338C4.05615 11.1338 3.78955 11.0005 3.54834 10.7021L0.260254 6.65234C0.0761719 6.43018 0 6.2207 0 5.99854C0 5.49072 0.374512 5.12256 0.89502 5.12256C1.20605 5.12256 1.42188 5.23682 1.63135 5.49707L4.38623 8.98828L9.7627 0.469727C9.97852 0.126953 10.1943 0 10.5498 0C11.064 0 11.4321 0.361816 11.4321 0.863281C11.4321 1.06006 11.375 1.26318 11.2227 1.4917L5.28125 10.6831C5.07812 10.9878 4.78613 11.1338 4.41162 11.1338Z"
      fill="white"
    />
  </svg>
);
