import React, { ReactNode } from "react";

import * as styles from "./Checkbox.module.scss";
import { FieldError } from "react-hook-form";
import { FormError } from "../../FormError";
import { CheckmarkIcon } from "../../Svg/CheckmarkIcon";

interface CheckboxProps {
  value: boolean;
  label: ReactNode;
  error?: FieldError;
  onBlur: () => void;
  onChange: () => void;
}

const Checkbox = ({ value, label, error, onBlur, onChange }: CheckboxProps) => {
  return (
    <div>
      <label className={styles.container}>
        <input
          type="checkbox"
          className={styles.input}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        <span className={styles.checkmarkBox}>
          <CheckmarkIcon />
        </span>
        <span className={styles.label}>{label}</span>
      </label>

      <FormError error={error?.message} />
    </div>
  );
};

export { Checkbox };
