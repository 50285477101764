import React, { useState, useRef, useEffect } from "react";
import { postContactUs, ContactUsData } from "../../api/contactApi";
import { Controller, useForm } from "react-hook-form";

import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "../Link";
import { getPrivacyUrl } from "../../utils/urls";
import { CAPTCHA_KEY } from "../../config";
import { useInView } from "react-intersection-observer";
import { Button } from "../Button";
import { Buttons } from "../Buttons";
import { FormError } from "../FormError";
import { Input } from "../form/Input/Input";
import { Text } from "../Text/Text";
import * as styles from "./ContactUs.module.scss";
import { Checkbox } from "../form/Checkbox/Checkbox";

function validate(values: ContactUsData) {
  const { name, email, message, consent } = values;
  const errors: any = {};
  if (!consent) {
    errors.consent = {
      message:
        "Mere formality, agree to our Privacy Policy to send your message",
    };
  }

  if (!name) {
    errors.name = {
      message: "Please tell us your name",
    };
  }

  if (!email) {
    errors.email = { message: "We need your email to reply" };
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = { message: "Not a valid email. Let's try again!" };
  }

  if (!message) {
    errors.message = { message: "Don't hesitate to share some details" };
  }

  // if (!captcha) {
  //   errors.captcha = "Oops, Captcha validation failed";
  // }

  return { values, errors };
}

const Status = {
  Initial: 0,
  Done: 1,
  Fail: 2,
};

type Props = { className?: string };

export const ContactForm: React.FC<Props> = ({ className }) => {
  const [status, setStatus] = useState(Status.Initial);
  const done = status === Status.Done;

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const doneRef = useRef<HTMLDivElement>(null);

  const [formRef, formInView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (done && doneRef.current)
      doneRef.current.scrollIntoView({ behavior: "smooth" });
  }, [done]);

  const {
    register,
    control,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: validate,
  });

  const onSubmit = async (values: ContactUsData) => {
    try {
      try {
        // console.log("captcha");
        if (!recaptchaRef.current) throw new Error("No Captcha");

        const token = await recaptchaRef.current.executeAsync();
        // console.log("captcha", recaptchaRef.current);

        values = { ...values, captcha: token! };

        // console.log("token", token);
      } catch (e) {
        console.error("Captcha: ", e);
        setError("captcha", { message: "Captha validation failed" });
        return;
      }

      try {
        values.message = `URL: ${window.location.href}\n` + values.message;
      } catch (e) {
        // nop
      }

      // console.log("submitting", values);
      await postContactUs(values);
      setStatus(Status.Done);
    } catch (e) {
      console.error(e);
      setStatus(Status.Fail);
    }
  };

  if (done)
    return (
      <div className="mt-3" ref={doneRef}>
        <p>
          <strong>Thank you,</strong>
        </p>
        <div className={styles.messageSentText}>
          <p>Your message was sent successfuly.</p>
          <p>We usually respond within 24 hours.</p>
          <p>Have a nice day!</p>
        </div>
      </div>
    );

  return (
    <form className={className} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Text>
        Tell us about you and your project. We usually reply within 24 hours.
      </Text>

      <div className={styles.formWrapper}>
        <div className="mb-4">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Your name" error={errors.name} />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Your E-mail"
                error={errors.email}
              />
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                multiline
                placeholder="Describe your project"
                error={errors.message}
              />
            )}
          />
        </div>

        <Controller
          name="consent"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              label={
                <>
                  I accept LeanyLabs{" "}
                  <Link ext to={getPrivacyUrl()}>
                    Privacy Policy
                  </Link>
                </>
              }
              error={errors.message}
            />
          )}
        />

        <div className="my-1">
          <Text>
            This site is protected by reCAPTCHA and the Google{" "}
            <Link to="https://policies.google.com/privacy">Privacy Policy</Link>{" "}
            and{" "}
            <Link to="https://policies.google.com/terms">Terms of Service</Link>{" "}
            apply.
          </Text>
        </div>

        {formInView && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={CAPTCHA_KEY}
          />
        )}
        <FormError error={errors.captcha?.message} />
        {(status === Status.Fail || errors.captcha) && (
          <FormError className="my-1">
            Oops, something went wrong. We'll fix it. Menawhile, please contact
            us by email{" "}
            <Link
              ext
              to={`mailto:hi@leanylabs.com?subject=Hello from ${encodeURIComponent(
                watch("name")
              )}&body=${encodeURIComponent(watch("message"))}`}
            >
              hi@leanylabs.com
            </Link>
          </FormError>
        )}

        <div className={styles.formButtons}>
          <Button type="submit">Submit</Button>
        </div>
      </div>
    </form>
  );
};
