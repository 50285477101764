import React, { useEffect } from "react";

export function useClickOutsideClose(
  ref: React.RefObject<any>,
  open: boolean,
  close: () => void
) {
  useEffect(() => {
    if (!open) return;

    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target)) {
        close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside, false);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, [open, close, ref]);
}
