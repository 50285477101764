const getCollectionUrl = (root: string, slug: string) => {
  if (!slug) return "#";
  if (!slug.startsWith("/")) slug = "/" + slug;
  if (!slug.endsWith("/")) slug = slug + "/";
  return root + slug;
};

export const getHomeUrl = () => "/";

export const getTermsUrl = () => "/legal/terms/";
export const getPrivacyUrl = () => "/legal/privacy/";
export const getCookieUrl = () => "/legal/cookie/";
export const getMondayAppsEULAUrl = () => "/legal/monday-apps-eula/";
export const getContactUrl = () => "#contact-us";
export const getAboutUsUrl = () => "/about-us/";

export const getServicesUrl = () => "/services/";
export const getTeamExtensionUrl = () => "/services/team-extension/";
export const getMondayAppsDevelopmentUrl = () =>
  "/services/monday-apps-development/";
export const getProductDevelopmentUrl = () => "/services/product-development/";

export const getMirrorColumnAutomationsProductUrl = () =>
  "/products/mirror-column-automations-monday-app/";

export const getSearchInUpdatesInstallationUrl = () =>
  "https://auth.monday.com/oauth2/authorize?client_id=f79599c9c1d4e4dad182d63b9f2264fb&response_type=install";

export const getBlogUrl = () => "/blog/";
export const getBlogPostUrl = (slug: string) => getCollectionUrl("/blog", slug);

export const getCaseStudiesUrl = () => "/case-studies/";
export const getCaseStudyUrl = (slug: string) =>
  getCollectionUrl("/case-studies", slug);

export const getVacanciesUrl = () => "/vacancies/";
export const getVacancyUrl = (slug: string) =>
  getCollectionUrl("/vacancies", slug);

export const getCompetencyMatrixUrl = () =>
  "https://leanylabs.notion.site/LeanyLabs-Competency-Matrix-20c7250bd2474f1c92aacb73b9c03f0c";
